/* Global Box Styles */
.MuiBox-root {
    box-sizing: border-box;
}

/* Change Attributions */
.mapbox-attribution-container .attrition-logo .mapboxgl-ctrl-logo {
    display: none;
}

.mapbox-attribution-container > div:last-child a[href='https://kepler.gl/policy/'] {
    display: none;
}

.mapbox-attribution-container > div:last-child a[href='https://www.mapbox.com/map-feedback/'] {
    display: none;
}